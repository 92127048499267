
a{
    transition: .3s;
    &:hover { 
        text-decoration: none;
        color: $link-hover-color;
        transition: .3s;
    }
} 

h1{
    font-weight: bold;
}
textarea {
	resize: none;
}

#index {
	.tab-content {
		margin-top: 35px;
		margin-bottom: 34px;
	}
}
ul.ul-none{
    list-style: none;
    margin: 0;
    padding:0;
}
.footer_links{
    list-style: none;
    margin: 0;
    padding: 15px 0;
}
.checkbox.checkbox-wr {
    padding: 7px 5px;
    border: 2px solid transparent;
    transition: all .3s;
    margin-top: 0;
    /*background: #fff;*/
    &.red{
        border-color: #e06060;
        background: #fff5f5;
    }
    
} 

#cookieNoticeContent{
    color: $base-text-color;
    a {
        color: $link-color;
    }
}
//text
p.info-title {
	font-weight: bold;
	color: $base-text-color;
	margin-bottom: 25px;
}

p.info-account {
	margin: -4px 0 24px 0;
}

.dark {
	color: $base-text-color;
}

.main-page-indent {
	margin-bottom: $base-page-indent;
}


.label {
	white-space: normal;
	display: inline-block;
	padding: 6px 10px;
}


.unvisible {
	display: none;
}


.close {
	opacity: 1;
	&:hover {
		opacity: 1;
	}
}



.more{
    padding: 10px 0;
    text-align: right;
}

//product elements
.product-name {
	font-size: 1.6rem;
	line-height: 1.3;
	color: $product-name-color;
    &:hover {
        color: $product-name-hover-color;
    }
}

.price {
	font-size: 1.8rem;
    font-weight: 600;
    font-family: $font-custom;
	color: $price-color;
	white-space: nowrap;
}

.old-price {
	color: $product-old-price-color;
	text-decoration: line-through;
	font-weight: normal;
    font-size: 1.5rem;
}

.special-price {
	color: $product-special-price-color;
}

.price-percent-reduction {
	background: #f13340;
	border: 1px solid #d02a2c;
	font: 600 21px / 24px $font-custom;
	color: $light-text-color;
	padding: 0 5px 0 3px;
	display: inline-block;
	&.small {
		font: 700 14px / 17px $font-family;
		padding: 1px 6px;
	}
}

.link-btn {
	font-weight: bold;
	i {
		margin-right: 5px;
		&.large {
			font-size: 26px;
			line-height: 26px;
			vertical-align: -3px;
			color: silver;
		}
	}
}



//BOXES
.box {
	background: $base-box-bg;
	border: 1px solid $base-border-color;
	padding: $base-box-padding;
	margin: $base-box-indent;
	line-height: 23px;
	>h3{
		margin: 5px 0 15px;
	}
	p {
		margin-bottom: 0;
	}
	&.box-small {
		padding: 9px 10px 9px 20px;
		.dark {
			padding-top: 10px;
			padding-right: 20px;
			margin-bottom: 0;
			width: auto;
		}
	}
    .form-control{
        background: #fff;
    }
    &.address{
        list-style: none; 
    }
    .page-subheading{
        margin-top: 5px;
    }
}

.page-product-box {
	padding-bottom: 10px;
}

.product-box {
	width: 178px;
	float: left;
	box-sizing: border-box;
	.product-image { 
		border: $base-border;
		background: white;
		padding: 5px;
		display: block;
		margin-bottom: 11px;
		img {
			max-width: 100%;
			width: 100%;
			height: auto;
		}
	}
}

//Homepage Tabs
#home-page-tabs {
	border: none;
	background: $light-background;
	// min 768px
	@media (min-width: $screen-sm) {
		padding: 10px 0;
		margin: 0 0 0 -18px;
	}
	> li {
		margin-bottom: 0;
		// min 768px
		@media (min-width: $screen-sm-max) {
			border-left: 1px solid $base-border-color;
			margin: 0 0px 0 9px;
			padding: 0 0px 0 9px;
		}
		// max 479px
		@media (max-width: $screen-xs - 1) {
			width: 100%;
		}
		a {
			font: 600 21px / 24px $font-custom;
			color: $base-heading-color;
			text-transform: uppercase;
			border: none;
			outline: none;
			margin: 0;
			padding: 10px;
			// min 768px
			@media (min-width: $screen-sm) {
				padding: 1px 10px;
			}
			@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
				padding: 10px 10px;
			}
		}
		&:first-child {
			border: none;
		}
	}
	> li.active a,
	>li a:hover {
		background: $dark-background;
		color: $light-text-color;
		padding: 10px;
		// min 768px
		@media (min-width: $screen-sm) {
			margin: -9px 0;
		}
		@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
			margin: 0px 0;
		}
	}
}

.block {
	@extend .clearfix;
	margin-bottom: 30px;
	
	.block_content {
		
		@media (max-width: $screen-xs-max) {
			margin-bottom: 15px;
		}
	}
}

h1.page-heading,
#center-column .block .title_block,
h2.title_block{
    padding: 1.5rem 1rem 2rem 5.5rem;
    &:before{
            display: block;
            width: 5rem;
            height: 5rem;
            position: absolute;
            left: 2px;
            top: 1rem;
            content: ' ';
            background: url( ../_dev/svg/icon-logo.svg );
            background-repeat: no-repeat;
            background-size: contain;

        }
}
h1.page-heading,
 .block .title_block,
h2.title_block{
		font-size: 3rem;
        font-weight: 400;
        
        position: relative;
        text-transform: uppercase;
        margin: 0 0 10px;
        color: $base-heading-color;
        
        a {
			color: $base-heading-color;
			&:hover {
				color: $link-hover-color;
			}
		}
	}
    

.block {
	h4 {
		font: 400 2.4rem / 2.4rem $font-custom;
		text-transform: uppercase;
		padding: $line-height-computed 5px 5px 5px;
		margin: 0 0 10px;
		color: $base-heading-color;
		a {
			color: $base-heading-color;
			&:hover {
				color: $link-hover-color;
			}
		}
	}
	
	.list-block {
		margin-top: -8px;
		// max 767px
		@media (max-width: $screen-xs-max) {
			margin-top: 0;
		}
		li {
			
			border-top: 1px solid $base-border-color;
			a {
				display: block;
				padding: 5px 0 6px 10px;
				i {
					display: none;
				}
				&:before {
					content: "\f105";
					display: inline;
					font-family: $font-icon;
					color: $base-text-color;
					padding-right: 8px;
				}

			}
			&:first-child {
				border-top: none;
			}
		}
		.form-group {
			padding-top: 20px;
			border-top: 1px solid $base-border-color;
			margin-bottom: 0;

		}
		.btn {
			margin-top: 12px;
		}
	}
	
}

.page-heading {
	font-size: 22px;
	font-weight: bold;
	padding: 0px 0px 5px 10px;
	margin-bottom: 20px;
	overflow: hidden;
	span.heading-counter {
		font: bold 13px / 22px $font-family;
		float: right;
		color: $base-text-color;
		text-transform: none;
		margin-bottom: 0px;
		@media (max-width: $screen-xs) {
			float: none;
			display: block;
			padding-top: 5px;
		}
	}
	span.lighter {
		color: #9c9c9c;
	}
	&.bottom-indent {
		margin-bottom: 16px;
	}

}
#right-column,
#left-column {
    ul.products{
        margin: 0;
        list-style: none;
        padding: 0;
        li{
            display: block;
            margin-bottom: 7px;
            border-bottom: 1px solid #eee;
            .text_desc,
            .content_img{
                display: block;
                float: left;
                width: 25%;
            }
            .text_desc{
                width: 75%;
                padding-left: 4px;
            }
            &:last-child{
                border-bottom:none;
            }
        }
        .s_title_block{
            margin-top: 0;
            a{
                color: $base-text-color;
            }
        }
    }
    .block {
        .title_block,
        h3{
            font-size: 2.5rem;
            border-bottom: 5px solid $brand-primary-dark;
            padding: 1.5rem .5rem 2rem .5rem;
            margin: 0 0 15px;
        }
    }
}



.page-subheading {
	font-family: $font-custom;
	font-weight: 600;
	text-transform: uppercase;
	color: $base-heading-color;
	font-size: 18px;
	padding: 0 0 5px;
	line-height: normal;
	margin-bottom: 12px;
	border-bottom: 1px solid $base-border-color;
}


.pack_content {
	margin: 10px 0 10px 0;
}

.confirmation {
	margin: 0 0 10px;
	padding: 10px;
	border: 1px solid #e6db55;
	font-size: 13px;
	background: none repeat scroll 0 0 lightyellow;
}

#page .rte {
	background: transparent none repeat scroll 0 0;

	em {
		font-style: italic !important;
	}
}


.header-container {
	background: $light-background;
}


#reinsurance_block {
	clear: both !important;
}

// Make sure lists are correctly displayed in tinyMCE BO edition mode too
#short_description_content ul,
#short_description_content ol,
#short_description_content dl,
#tinymce ul,
#tinymce ol,
#tinymce dl {
	margin-left: 20px;
}

.rte ul {
	list-style-type: disc;
	padding-left: 15px;
}

.rte ol {
	list-style-type: decimal;
	padding-left: 15px;
}

.block_hidden_only_for_screen {
	display: none;
}




//PAGE SEARCH
#instant_search_results {
	a.close {
		font-size: 13px;
		font-weight: 600;
		opacity: 1;
		line-height: inherit;
		text-transform: none;
		font-family: $font-family;
		&:hover {
			color: $link-hover-color;
		}
	}
}


//DISCOUNT PAGE
table.discount {
	i {
		font-size: 20px;
		line-height: 20px;
		vertical-align: -2px;
		&.icon-ok {
			color: #46a74e;
		}
		&.icon-remove {
			color: #f13340;
		}
	}
}

//GUEST TRACKING
#guestTracking {
	.form-control {
		max-width: 271px;
	}
	// > 1200px
	@media (min-width: $screen-lg) {
		.form-group {
			overflow: hidden;
			i {
				padding-left: 10px;
			}
		}
		.form-control {
			float: left;
		}
		label {
			float: left;
			clear: both;
			width: 100%;
		}
	}
}

//PAGE 404
#pagenotfound {
	.pagenotfound {
		max-width: 824px;
		margin: 100px auto 100px auto;
		text-align: center;
		.img-404 {
			padding: 8px 0 27px 0;
			img {
				max-width: 100%;
			}
		}
		h1 {
			font: 600 28px / 34px $font-custom;
			color: $base-text-color;
			text-transform: uppercase;
			margin-bottom: 7px;
		}
		p {
			font: 600 16px / 20px $font-custom;
			color: #555454;
			text-transform: uppercase;
			border-bottom: 1px solid $base-border-color;
			padding-bottom: 19px;
			margin-bottom: 20px;
		}
		h3 {
			font-weight: bold;
			color: $base-text-color;
			font-size: 13px;
			line-height: normal;
			margin-bottom: 18px;
		}
		label {
			font-weight: normal;
			@media (max-width: $screen-xs-max) {
				display: block;
			}
		}
		.form-control {
			max-width: 293px;
			display: inline-block;
			margin-right: 5px;
			// max 767px
			@media (max-width: $screen-xs-max) {
				margin: 0 auto 15px auto;
				display: block;
			}
		}
		.buttons {
			padding: 48px 0 20px 0;
			.button-medium {
				i.left {
					font-size: 17px;
				}
			}
		}
	}
}

//addon RerversoForm
#account-creation_form fieldset.reversoform {
	padding: 10px !important;
}

#account-creation_form fieldset.reversoform .text label {
	display: block;
	padding: 0 0 10px 0;
	width: 100%;
	text-align: left;
}

#account-creation_form fieldset.reversoform .infos-sup {
	padding: 0 !important;
	font-size: 10px;
	font-style: italic;
	text-align: right;
}

//addon customer privacy
#account-creation_form fieldset.customerprivacy label,
fieldset.customerprivacy label {
	cursor: pointer;
}

//addon referralprogram
.tab-content {
	margin-top: 20px;
}


//CSS Modules
//IMPORTANT - Internet Explorer can read 31 CSS files max. Some CSS have been put here and erased from their own module folder.

//Block ADVERTISING
.advertising_block {
	width: 191px;
	margin-bottom: 1em;
	text-align: center;
}

//footer
.blockcategories_footer ul ul {
	display: none !important;
}

//Form validate
.form-group {
	&.form-error {
		input, textarea {
			border: 1px solid #f13340;
			color: #f13340;
			background: url(../img/icon/form-error.png) 98% 5px no-repeat #fff1f2;
		}
	}
	&.form-ok {
		input, textarea {
			border: 1px solid #46a74e;
			color: #35b33f;
			background: url(../img/icon/form-ok.png) 98% 5px no-repeat #ddf9e1;
		}
	}
	&.form-error, &.form-ok {
		.form-control {
			padding-right: 30px;
		}
	}
}

.fancybox-skin {
	background: $light-background !important;
}

.fancybox-skin .fancybox-close {
	width: 28px;
	height: 28px;
	background: none;
	font-size: 28px;
	line-height: 28px;
	color: $base-text-color;
	text-align: center;
	background: white;
	border-radius: 50px;
	&:hover {
		color: $link-hover-color;
	}
	&:after {
		content: "\f057";
		font-family: $font-icon;
	}
}

//Homepage pre-footer
#facebook_block,
#cmsinfo_block {
	overflow: hidden;
	min-height: 344px;
	height: 344px;
	padding-right: 29px;
	padding-left: 29px;
	// max 991px
	@media (max-width: $screen-sm-max) {
		min-height: 354px;
		height: 354px;
		padding-left: 13px;
		padding-right: 13px;
	}
	// max 767px
	@media (max-width: $screen-xs-max) {
		width: 100%;
		min-height: 1px;
	}
}

#facebook_block {
	h4 {
		padding: 35px 0 0 0;
		margin: 0 0 12px 0;
		font: 300 21px / 25px $font-custom;
		color: lighten($base-heading-color, 10%);
		// max 991px
		@media (max-width: $screen-sm-max) {
			font-size: 18px;
		}
		// max 767px
		@media (max-width: $screen-xs-max) {
			padding-top: 20px !important;
		}
		// 768px
		@media (max-width: $screen-sm) {
			font-size: 16px;
		}
	}
	.facebook-fanbox {
		background: $light-background;
		border: 1px solid #aaaaaa;
		padding-bottom: 10px;
	}
}

#cmsinfo_block {
	// max 767px
	@media (max-width: $screen-xs-max) {
		border: none;
		margin-top: 10px;
	}
	> div {
		padding: 35px 10px 0 0;
		// max 767px
		@media (max-width: $screen-xs-max) {
			padding-top: 20px;
		}
		// max 479px
		@media (max-width: $screen-xs - 1) {
			width: 100%;
		}
	}
	> div + div {
		min-height: 344px;
		padding-left: 29px;
		// max 479px
		@media (max-width: $screen-xs - 1) {
			border-left: none;
			padding-left: 10px;
			min-height: 1px;
			padding-bottom: 15px;
		}
	}
	em {
		float: left;
		width: 60px;
		height: 60px;
		margin: 3px 10px 0 0;
		font-size: 30px;
		color: $light-text-color;
		line-height: 60px;
		text-align: center;
		background: lighten($base-heading-color, 10%);
		border-radius: 100px;
		// max 991px
		@media (max-width: $screen-sm-max) {
			width: 30px;
			height: 30px;
			line-height: 30px;
			font-size: 20px;
		}
	}
	.type-text {
		overflow: hidden;
	}
	h3 {
		margin: 0 0 5px 0;
		font: 300 21px / 25px $font-custom;
		color: lighten($base-heading-color, 10%);
		// max 1199px
		@media (max-width: $screen-md-max) {
			font-size: 18px;
		}
	}
	ul {
		li {
			padding-bottom: 22px;
			// max 1199px
			@media (max-width: $screen-md-max) {
				padding-bottom: 10px;
			}
			// max 991px
			@media (max-width: $screen-sm-max) {
				padding-bottom: 0;
			}
		}
	}
	p {
		em {
			background: none;
			border-radius: 0;
			margin: 0;
			font-size: 13px;
			color: $text-color;
			float: none;
			height: inherit;
			line-height: inherit;
			text-align: left;
			font-style: italic;
		}
	}
}

.ie8 {
	#facebook_block,
	#cmsinfo_block {
		height: 344px;
	}
}

//Zoom
// max 1199px
@media (max-width: $screen-md-max) {
	.zoomdiv {
		display: none !important;
	}
}

//temporary fix for quickview
#product.content_only {
	div.pb-left-column {
		p.resetimg {
			margin-bottom: 0px;
			padding-top: 0px;
		}
		#thumbs_list_frame {
			margin-bottom: 0px;
		}
	}
}

//Product Compare
#product_comparison {
	td.compare_extra_information {
		padding: 10px;
	}
	#social-share-compare {
		margin-bottom: 50px;
	}
}




.tnc_box {
  background: #fbfbfb;
  border: 1px solid #d6d4d4;
  padding: 14px 18px 13px;
  margin: 0 0 30px 0;
  line-height: 23px;
}

.pointer-box {
  cursor: pointer;
}
.search-back{
    padding: 10px 0;
    text-align: right;
}
#right-column {
    #viewed-products_block_left,
    #special_block_right,
    #manufacturers_block_left{
        padding: 15px;
    }
}