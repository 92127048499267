//
// BREADCRUMB
//
.breadcrumb {
	padding: 10px 0;
}
.navigation-pipe{
    display: inline-block;
    width: 10px;
    text-indent: -999px;
    position: relative;
    &:after{
        @include fa-icon();
        width: 12px;
        height: 14px;
        position: absolute;
        content: $fa-var-angle-right;
        top: 3px;
        left: 3px;
        text-indent: 0;
    }
}
.ie8 {
	.breadcrumb {
		a:after {
		}
		.navigation-pipe {
		}
	}
}
