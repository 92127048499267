
.product-labels{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 20;
    display: inline-block;
}
.product-label{
    display: inline-block;
    color: #fff;
    padding: 0px;
    border-radius: 0px;
    width: 4rem;
    line-height: 4rem;
    vertical-align: middle;
    height: 4rem;
    font-size: 2.8rem;
    font-weight: 600;
    text-align: center;
    &.new{
        background: $brand-secondary;
    }
    &.sale{
        background: $brand-primary;
    }
    &.discount{
        background: $brand-primary;
    }
}

.product-tile{
    text-align: center;
    transition: .5s;
 //   max-width: 350px;
    .content_price{
        text-align: left;
        padding-bottom: 10px;
        height: 3.6rem;
        line-height: 3.6rem;
        float: left;
        width: calc( 100% - 5rem );
    }
    .product-name{
        padding: 0 20px;
        display: block;
        height: 3.8rem;
        font-size: 1.4rem;
        text-align: left;
        color: $base-text-color;
    }
    .bottom{
        padding: 0 20px;
    }
    .ajax_add_to_cart {
    	position: relative;
		padding: 4px 1px;
        display: inline-block;
        &:hover{
            /*background: $brand-primary;*/
            svg{
                transition: all .4s;
                fill: $brand-primary;
            }
        }
        > svg, img{
            width: 34px;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            top: -1px;
            margin-right: 3px;
        }
        svg{
            transition: all .4s;
            fill: $base-text-color;
        }
    }

    .image-container{
        position: relative;
        a{
            display: block;
        }
        img{
            margin: 0 auto;
        }
    }
    .right-block {
    	padding-bottom: 10px;
    }
    .btn-container{
        float: left;
        width: 5rem;
    	.btn{
    		text-transform: uppercase; 
		}
    	.btn-view{
            color:  $base-text-color;
    		padding: 0px 10px;
            font-size: 3rem;
            &:hover{
                color:  $brand-primary;
            }
    	}
    }
    .wrapper{
    }
    &:hover{
        transition: .5s;
    }
}



.product-list-wrapper{
    &.main{
        padding: 15px 0;
    }
}
.product_list.slider,
.product_list.grid {
    .product-tile {
		position: relative;
		z-index: 0;
        display: block;
        &:hover{
	 		box-shadow: 0 0 15px rgba(0,0,0,.3);
	 		z-index:5;
		}
    }
}
.product_list.slider{
    padding: 10px 0;
    .product-tile:not(.swiper-slide-active){
        .wrapper{
        }
    }
}

.product_list.grid {
    .product-tile {
        width: percentage( 1 / $nbXs ); 
        float: left;
    
        @media (max-width: 480px){ 
            &:nth-of-type(#{$nbXs}n+1){
                clear: left;
                .wrapper{
                }
            }
            @for $i from 2 through $nbXs {
                &:nth-of-type(#{$i}){
                    .wrapper{
                    }
                }
            }
        }
        .wrapper{
            border-top: none;
            border-left: none;
        }
        
        &:first-child{
            .wrapper{
            }
        }
	 	
    }

    .product_link_more,
    .product-desc{
        display: none;
    }
}



@media (min-width: 480px) AND (max-width: $screen-sm-max)  {
    .product_list{ 
        &.grid {
            .product-tile {
                width: percentage( 1 / $nbSm );
                &:nth-of-type(#{$nbSm}n+1){
                    clear: left;
                    .wrapper{
                    }
                }
                @for $i from 2 through $nbSm {
                    &:nth-of-type(#{$i}){
                        .wrapper{
                        }
                    }
                }                
            }
        }
    }
}
@media (min-width: $screen-md-min) AND (max-width: $screen-md-max) {
    .product_list{ 
        &.grid {
            .product-tile {
                width: percentage( 1 / $nbMd );
                .wrapper{
                    height: 36rem;
                }
                &:nth-of-type(#{$nbMd}n+1){
                    clear: left;
                    .wrapper{
                    }
                }
                @for $i from 2 through $nbMd {
                    &:nth-of-type(#{$i}){
                        .wrapper{
                        }
                    }
                }
            }
        }
    }
    .hide-left-column #center-column .product_list { 
        &.grid {
            .product-tile {
                .wrapper{
                    height: 36rem;
                }
            }
        }
    }
}
@media (min-width: $screen-lg-min) { 

    .product_list.grid:not(._full-width) {
            .product-tile {
                width: percentage( 1 / $nbLg );
                .wrapper{
                    height: 36rem;
                }
                &:nth-of-type(#{$nbLg}n+1){
                    clear: left;
                    .wrapper{
                    }
                }
                @for $i from 2 through $nbLg {
                    &:nth-of-type(#{$i}){
                        .wrapper{
                        }
                    }
                }
            }
    }
    .product_list.grid._full-width{
            .product-tile {
                width: percentage( 1 / ( $nbLg + 1 ) );
                &:nth-of-type(#{ 4 }n+1){
                    clear: left;
                    .wrapper{
                    }
                }
                @for $i from 2 through ( $nbLg + 1 ) {
                    &:nth-of-type(#{$i}){
                        .wrapper{
                        }
                    }
                }
            }
        }
}

