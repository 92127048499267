
.btn.btn-plus,
.btn.btn-minus {
	font-size: 14px;
	width: 27px;
	height: 27px;
	line-height: 27px;
	padding: 0;
	text-align: center;
	vertical-align: center;
}
.btn-checkout{
    font-size: 22px;
    padding: 9px 20px;
 	&:hover:disabled,
    &:disabled{
    	border-color: $gray-light;
    	background-color: $gray-light;
    }
}
.btn-black{
    background: #000;
    &:hover{
        background: #333;
        color: $brand-primary;
    }
}
.btn {
    transition: .3s;
    &:hover{
        transition: .3s;
    }
}
.btn-secondary {
    @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
    fill: $btn-secondary-color;
    &:hover, &:focus{
        background: darken($btn-secondary-bg,11%);
        border-color: darken($btn-secondary-bg,11%);
    }
}
//Social Sharing
.btn-facebook, .btn-twitter, .btn-google-plus, .btn-pinterest {
    color: white;
    border-color: transparent;
    i {
        color: white;
    }
	&:hover {
        border-color: transparent;
        color: white;
	}
}

.btn-facebook {
    background-color: #435f9f;
	&:hover {
		background-color: darken(#435f9f,12%);
	}
}

.btn-twitter {
	background-color: #00aaf0;
	&:hover {
		background-color: darken(#00aaf0,12%);
	}
}

.btn-google-plus {
	background-color: #e04b34;
	&:hover {
		background-color: darken(#e04b34,12%);
	}
}

.btn-pinterest {
    background-color: #ce1f21;
	&:hover {
		background-color: darken(#ce1f21,12%);
	}
}


.btn.disabled,
.btn[disabled]:hover {
	opacity: 0.3;
}