

// MANUFACTURERS/SUPPLIERS
#manufacturers_list,
#suppliers_list {
    
	h3 {
		font-size: 17px;
		line-height: 23px;
	}
	.description {
		line-height: 18px;
	}
	.product-counter {
		a {
			font-weight: bold;
			color: $base-text-color;
			&:hover {
				color: $link-hover-color;
			}
		}
	} 
	&.list {
        list-style: none;
        margin: 0;
        padding: 0;
        > li{
            margin: 0;
            padding: 0; 
        
			.mansup-container {
				border-top: 1px solid $base-border-color;
				padding: 31px 0 30px 0;
				// < 768
				@media (max-width: $screen-xs-max) {
					text-align: center;
				}
			}
			&:first-child {
				.mansup-container {
					border-top: 0;
				}
			}
			.left-side {
				text-align: center;
			}
			h3 {
				margin-top: -5px;
				padding-bottom: 8px;
			}
			.middle-side {
				padding-left: 0;
				// < 768
				@media (max-width: $screen-xs-max) {
					padding-right: 15px;
					padding-left: 15px;
				}
			}
			.right-side-content {
				border-left: 1px solid $base-border-color;
				padding: 0 0 32px 31px;
				min-height: 108px;
				// < 768
				@media (max-width: $screen-xs-max) {
					min-height: 1px;
					padding: 0;
					border: none;
				}
				.product-counter {
					position: relative;
					top: -6px;
					margin-bottom: 12px;
					// < 768
					@media (max-width: $screen-xs-max) {
						top: 0;
					}
				}
			}
		}
	}
	
}

#manufacturer,
#supplier {
	.description_box {
		padding-top: 20px;
		padding-bottom: 20px;
		border-top: 1px solid $base-border-color;
		.hide_desc {
			display: none;
		}
	}
	.bottom-pagination-content,
	.top-pagination-content {
		min-height: 69px;
	}
	.top-pagination-content.bottom-line {
		border-bottom: 1px solid $base-border-color;
	}
}
