

#search-top {
	clear: both;
    text-align: center;
    width: 100%;
    padding: 10px 15px 0 15px;
    position: relative;
	.btn-search {
        height: $header-element-height;
		font-size: 20px;
		border: none;
		padding: 8px 15px;
		vertical-align: top;
        width: 48px;
        position: absolute;
        right: 0;
        top:0;
        z-index:10;
	}
    > form {
        position: relative;
        display: block;
        z-index: 1;
    }
}
#search-top-query{
    display: block;
    width: 100%;
    padding: 11px 65px 11px 15px;
	height: $header-element-height;
    position: relative;
    z-index: 1;
}

.ac_results {
	background: #ffe0f0;
	border: 1px solid $base-border-color;
	width: 271px;
	margin-top: -1px;
	li {
		padding: 0 10px;
		font-weight: normal;
		color: #686666;
		font-size: 13px;
		line-height: 22px;
		&.ac_odd {
			background: #ffd5e5;
		}
        &.ac_even {
            background: #ffe0f0;
        }
		&:hover, &.ac_over {
			background: #fFd0e0;
		}
	}
}


@media(min-width: $screen-md-min){
    #search-top {
    	padding: 30px 15px 0 15px;
    
        float: left;
        clear: none;
        width: 310px;
    }

}
@media(min-width: $screen-lg-min){

    #search-top {
        width: 410px;
    }
}