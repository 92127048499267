
$nbXs: 1;
$nbSm: 2;
$nbMd: 3;
$nbLg: 3; 

//------- FONTS
$font-family:				  		'Lato', Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-custom:                 		'Lato', sans-serif;
$font-icon:                   		"FontAwesome";
$font-family-sans-serif:    $font-family; 
$font-family-serif:         $font-family;

//  override before
$border-radius-base:        0px;
$border-radius-large:       2px;
$border-radius-small:       0px;
$base-border:		 				1px solid $base-border-color;
$headings-font-weight   : 400;

@import 'bootstrap/_variables'; 

$grid-margin : $grid-gutter-width /2;
$header-element-height: 4.2rem;
$top-icon-height : $header-element-height;
//--------- Indents
$base-page-indent:                 	$grid-gutter-width;
$base-box-padding:                  $grid-margin $grid-margin;
$base-box-indent:                  	0 0 $grid-gutter-width 0;
$base-listing-grid-padding:					$base-box-padding;

