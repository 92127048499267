//
//
body{
    position: relative;
}

#page,
#columns{
    position: relative;
}

#right-column{
    padding-top: $grid-gutter-width;
}
.loading-overlay{
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    bottom:0; 
    right: 0;
    background: $overlay-background;
    text-align: center;
    z-index: 9000;
    > div{
        position: relative;
        width: 100%;
        height:100%;
        >div{
            color: $overlay-spiner-color;
            display: block;
            width: 100%;
            line-height:0;
            vertical-align: middle;
            position: absolute; 
            top: 50%;
            transform: translateY(-50%);
            font-size: 30px;
            text-shadow: 5px 5px 5px rgba(50,50,50,.3);
        }
    }
    .fa{
        font-size: 70px;
        vertical-align: middle;
    }
}

