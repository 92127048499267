


$brand-primary      : #E72886;
$brand-primary-dark : #D23586;
$brand-secondary    : #0586E2; 
$grid-padding: 15px; 
$brand-danger       : $brand-primary; 
//--------- BACKGROUNDS
$base-box-bg            : #fbfbfb;
$table-background       : $base-box-bg;
$top-nav-background     : #FFF;
$top-nav-color          : #666; 
$dark-background        : #777; 
$body-bg                : #fff;
$light-background       : #fff;
$input-bg               : #F9F9F9;
$link-color :  $brand-primary;
$link-hover-color : $brand-secondary;

$footer-background      : #e5e5e5;
$footer-color           : #777;
$footer-header-color    : #333;
$footer-header-border   : #666;
$footer-hover-color     : $brand-primary;

$hm-menu-color : $dark-background;
$header-icon-hover-color: #fff;


$overlay-background : rgba(0,0,0,.4); 
$overlay-spiner-color  : #444;

$green      :   #70B244;
$on-stock-label : $green;
$new-label : $green;
$sale-label : $green; 


//--------- COLORS
$base-text-color:                   #888;
$base-heading-color:				#333;
$base-title-color:					$base-text-color;
$light-text-color:                  #fff;

$link-hover-decoration : none;
$description-color:					#666;


$btn-primary-color    : #FFF;
$btn-primary-bg       : $brand-primary;
$btn-secondary-color    : #FFF;
$btn-secondary-bg       : $brand-secondary;
$btn-secondary-border   : $brand-secondary;

$hm-menu-color  : #fff;
$hm-menu-background-color : $brand-primary;
$hm-menu-left-background-color : #000;
 

//-------- BORDERS
$base-border-color:					#eee;
$product-tile-border-color  :$base-border-color;
$dark-border-color:					#333;


//---------- Product Elements
$product-name-color:                #3a3939;
$product-name-hover-color:          #515151;
$price-color:				$base-heading-color;
$product-reduced-price:				#f00;
$product-special-price-color:		$brand-primary;
$product-old-price-color:			#6f6f6f;

//---------- Buttons
$button-text-color:					$light-text-color;

		//------ Buttons +/- ---
		$button-pm-color:			#c0c0c0;
		$button-pm-hover-color:		$base-text-color;

		//------ Button Small --
		$button-small-bg:			#6f6f6f;
		$button-small-hover-bg:		#575757;

		//-------Button exclusive-medium
		$button-em-color:			$base-text-color;

//---------- Product Listing
$base-listing-border-color:					$base-border-color;

		//-------------- Grid/List vars
		$GL-text-color:								#808080;
		$GL-title-color:							$base-text-color;
		$GL-icon-color:								#e1e0e0;
		$GL-icon-color-hover:						$base-text-color;

		//-------------- Pagination vars
		$pagination-prevnext-color:					#777676;
		$pagination-bgcolor:	               		$base-box-bg;
		$pagination-hover-bg:						#f6f6f6;

//---------- Product Info
$top-line-color:									#C4C4C4;
$product-image-border:								#dbdbdb;
$scroll-button-color:								#c0c0c0;
$list-info-bg:										#f6f6f6;
$scroll-button-hover-color:							$base-text-color;
$tr-even-bg:										#fdfdfd;

$alert-danger-bg:             #ffe0e0;
$alert-danger-text:           #da0400;;
$alert-danger-border:         #da0400;;