

#cookie_law_informations{
    .cookie-inner{
        padding: 20px 15px;
        background: rgba(0,0,0,.5);
    }
    a{
        color: $brand-primary;
        &:hover{
            color: darken($brand-primary,5%);
        }
    }
}