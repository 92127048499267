
@import "variables/colors";
@import "variables/theme_variables";

@import "bourbon/bourbon";
@import "_sort_pagi_bar";

//
//
//
 

.product_list {
	list-style: none;
    
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 10;
    &.list,
    &.grid{
		display: block; 
    }
}

