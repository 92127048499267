
$hm-width   :  #{$top-icon-height+.2};
$hm-height  : $top-icon-height;
$hm-menu-left-background-color : #000 !default;

#hm-menu{
    display: inline-block;
    float: left;
    width: $hm-width;
    height: $hm-height;
    position: relative;
    z-index: 101;
    >.menu-container{
        top: 0;
        right: 0;
        position: absolute;
        width: calc( 100vw - 62px);
        z-index: 105;
        transition: .3s;
       
    }
    &._open >.menu-container{
        transition: .3s;
        transform: translateX(100%);
    }
    
    .menu-container > button {
        display: block;
        top: 0;
        right: 0;
        z-index: 1000;
        position: absolute;
        overflow: hidden;
        margin: 0px;
        padding: 0;
        width: $hm-width;
        height: $hm-height;
        font-size: 0;
        text-indent: -9999px;
        appearance: none;
        box-shadow: none;
        border-radius: none;
        border: none;
        cursor: pointer;
        transition: background 0.3s;
        background: $hm-menu-background-color;
        span {
            display: block;
            position: absolute;
            top: 19px;
            left: 9px;
            right: 9px;
            height: 3px;
            background: $hm-menu-color;
            transition: background 0s 0.3s;
            &::before,
            &::after {
                position: absolute;
                display: block;
                left: 0;
                width: 100%;
                height: 3px;
                background-color: $hm-menu-color;
                content: " ";
                transition-duration: 0.3s, 0.3s;
                transition-delay: 0.3s, 0s;
            }
            &::before {
                top: -8px; 
                transition-property: top, transform;
            }
            &::after {
                transition-property: bottom, transform;
                bottom: -8px;
            }
        }
        &:focus, &:hover{
            outline: none;
            background: $brand-primary;
        }
    }
    &._open .menu-container > button {
        span{
                background: none;
                &::before {
                    top: 0;
                    transform: rotate(45deg);
                }
                &::after {
                    bottom: 0;
                    transform: rotate(-45deg);
                } 
                &::after,
                &::before {
                    transition-delay: 0s, 0.3s;
                }
            }
    }
    &._open #hm-overlay{
        display: block;
    }
    .grower{
        width: 40px;
        height: 38px;
        position: absolute;
        display: block;
        right: 0;
        top: 0;
        font-size: 26px;
        line-height: 40px;
        text-align: center;
        color: #FFF;
        transition: 0.3s;
        &:before{
            transition: 0.3s;
            @include fa-icon();
            content: $fa-var-angle-down;
            color: inherit;
        }
    }
    li._open > .grower{
        &:before{
            transition: 0.3s;
            content: $fa-var-angle-up;
        }
    }
}

#hm-menu-menu {
    position: absolute;
    top: 0;
    right: $hm-width; 
    display: block;
    background: $hm-menu-left-background-color;
    color: #fff;
    list-style: none;
    padding: 0;
    margin:0;
    width: 100%;
    ul{
        padding: 0;
        margin:0;
        max-height: 0;
        transition: .3s;
        overflow: hidden;
        
    }
    li._open > ul{
        max-height: 800px;
        transition: .3s;
    }
    li._open{
        
         > a {
            transition:  0.3s;
            background: lighten($hm-menu-left-background-color,10%) ;
           
        }
        > .grower{
            transition:  0.3s;
        }
    }
    ul, li, a{
        display: block;
    }
    a{
        color: #fff;
        font-size: 18px;
        transition:  0.3s;
        line-height: 18px;
        padding: 10px 45px 10px 20px;
    }
    li{
        position: relative;
    }
    > li{
        > a{

        }
        border-bottom: 1px solid lighten($hm-menu-left-background-color,10%);
        &:last-of-type{
            a {
                border-bottom: none;
            }
        }
        > ul {
            > li{
                > a{
                    font-size: 16px;
                    padding-left: 40px;
                }
                > ul{
                    > li{
                        a{
                            font-size: 14px;
                            padding-left: 55px;
                        }
                    }
                }
            }
        }
    }
}

#hm-overlay{
    display: none;
    transition: none;
    position: fixed;
    z-index: 100;
    background: rgba(0,0,0,.5);
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

@media(min-width:  $screen-sm-min){

    #hm-menu >.menu-container {
        width: 320px;
        
    }
    #hm-menu{
        &._open >.menu-container{
            transform: translateX( 320px);
        }
    }
}

@media(min-width: $screen-md-min){
    #hm-menu{
        display: none;
    }
}
