
// barre comparaison / tri
.sortPagiBar {
    text-align: center;
    padding: 5px 0px 0px 0px;
    margin: 0px;
    
    clear: both;
    input,
    select,
    span,
    label{
        white-space: nowrap;
        float: none;
        display: inline-block;
        font-weight: normal;
    }
    label{
        padding-right: 7px;
    }
    select{
        margin-right: 5px;
    }
    &.instant_search {
        #productsSortForm {
            display: none;
        }
    }
    .compare-form{
        float: right;
        .btn{
            padding: 4px 10px;
        }
    }
}
.bottom .sortPagiBar {
    padding: 15px 0px 15px 0px; 
    margin-top: 0px;
}
#productsSortForm {
    display: block;
    white-space: nowrap;
    margin-right: 20px;
    margin-bottom: 10px;
    select {
        max-width: 192px;
        @media (max-width: $screen-sm-max) {
            max-width: 160px;
        }
    }
}
.nbrItemPage {
    display: block;
    white-space: nowrap;
        margin-right: 20px;
    margin-bottom: 10px;
    select {
        max-width: 65px;
        padding: $padding-base-vertical;
    }
}
@media(min-width: $screen-xs-min){
    .sortPagiBar {   
        text-align: left;
    }
    .nbrItemPage,
    #productsSortForm {
        display: inline-block;
    }
    #productsSortForm{
        margin-bottom: 0;
    }
}	
