//
//  pagination
//
  
.pagination {
    margin: 0;
    float: right;
    display: inline-block;
    padding: 0;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    .showall {
        float: right;

        .btn {
            span {
                font-size: 13px;
                padding: 3px 5px 4px 5px;
                line-height: normal;
            }
        }
    }
    li{
        display: inline-block;
        > span, > a{
            display: inline-block;
            padding: 4px 5px;
            min-width: 30px;
            text-align: center;

        }
        > a:hover{
            background: $brand-primary;
            color: #fff;
        }
    }
}
ul.pagination {
    padding: 0;
    margin: 0;
    list-style: none;
}
.product-count {
    line-height: 27px;
}
@media(max-width: $screen-xs-max){
    .pagination {
        float: none;
    }
}