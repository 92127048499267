
#header-logo{
    width: 300px;
    margin: 10px auto;
    padding: 0px 15px;
}



#top-nav{
    background: $top-nav-background;
    position: fixed;
    z-index: 999;   
    top: 0;
    left: 0;
    width: 100%;
    &._scroll{
        box-shadow: 0 5px 15px rgba(0,0,0,.5);
    }

}
#social_block{
    line-height: 4.2rem;
    height: 4.2rem;
    ul{
        display:inline-block;
        margin: 0;
        padding: 0 15px;
        list-style: none;
    }
    li{
        display:inline-block;
        margin: 0;
        padding: 0;
        float: left;
    }
    span{
        min-width: 17px;
        text-align: center;
    }
    
    .tnav-block{
        padding: 0 7px;
    }
}
#header{
    padding-top: $top-icon-height;
}
@media(max-width: 560px){
    #social_block{
       width: 100%;
       text-align: center;
    }
    ._scroll #social_block{
        display: none; 
    }
    #header {
        padding-top: 2 * $top-icon-height;
    }
}

.tnav-block{
    color: $top-nav-color; 
    height: $top-icon-height;
    line-height: $top-icon-height;
    padding: 0 15px;
    font-size: 1.4rem;
    position: relative;
    display: inline-block;
    float: right;

}
a.tnav-block{
    &:focus,
    &:hover{
        background:$brand-primary;
        color: #fff;
        outline: none;
    }
}
#top-container{
    padding-top: 10px;
    text-align: center;
}

@media(max-width: $screen-sm-max){
    #top-nav{
        .container{
            width: 100%;
        }
    }
}
@media(min-width: $screen-sm-min){
    #header-logo{
        float: left;
        width: 300px;
        padding: 0px 15px 0px;
    }

}
@media(min-width: $screen-md-min){
    #header-logo{
        width: 300px;
    }

}
@media(min-width: $screen-lg-min){
    #header-logo{
        width: 300px;
        margin: 0;
    }

}
@media(max-width:$screen-xs-max){
    .tnav-block.login span{
        display: none;
    }
}